<template>
    <div class="panel has-text-weight-semibold">
        <b-loading v-model="isLoading" >
            <b-icon
                pack="fas"
                icon="sync-alt"
                size="is-large"
                custom-class="fa-spin">
            </b-icon>                
        </b-loading>        
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-11 has-text-centered">REVENUE SUMMARY REPORT</div>
                <div class="column has-text-right is-1">
                    <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                </div>                
                <!-- <div class="column has-text-right is-1">
                    <b-button class="is-dark is-small" @click="generatePDF">Print</b-button>
                </div>                     -->
                
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <date-range-selector
                    :dateRange="salesDateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector>
            </div>
            <div class="column">

            </div>
        </div>

        <div id="ReportContent">
            <div class="columns" v-if="showStoreName">
                <div class="column">
                    <div class="columns has-text-centered has-text-weight-bold">
                    <div class="column is-size-2 is-12">{{ getActiveStore.storeName }}</div>
                </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="columns has-text-centered has-text-weight-bold">
                    <!-- <div class="column is-size-2 is-12">{{ monthName }} {{ month.getFullYear() }}</div> -->
                    <div class="column is-size-5 is-12">Period: {{ salesDateRange[0] | formatDate }} to {{ salesDateRange[1] | formatDate }}</div>
                </div>
                </div>
            </div>
            <div class="columns">

                <div class="column">
                    <div class="is-size-4 has-text-weight-bold has-text-centered has-background-grey-lighter">
                        PROFIT AND LOSS STATEMENT
                    </div>
                    <br>
                    <div class="panel">

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Fuel Revenue
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="fuel.product" v-for="fuel in profitAndLossData.fuelSalesList">
                                <div class="column is-4 has-text-left">
                                    <!-- {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }} -->
                                    {{ toTitleCase(fuel.product) }}
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (fuel.saleAmount / fuel.saleVolume) | formatCurrency}}
                                </div>                                
                                <div class="column is-2 has-text-right">
                                    {{ ( fuel.saleAmount / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ fuel.saleAmount | formatCurrency}}
                                </div>
                            </div>

                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Fuel Revenue
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (totalFuelRevenue / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalFuelRevenue | formatCurrency}}
                                </div>
                            </div> 

                            </section>
                        </div>
                        <br>

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Merchandise Revenue
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="dept.storeMapping" v-for="dept in sortArrayByProp(deptSales,'storeMappingName', 'asc')">
                                <div class="column is-6 has-text-left">
                                    {{dept.storeMappingName}}
                                </div>
                                <!-- <div class="column is-2 has-text-right">
                                    {{ ((dept.netSalesValue - dept.cost) / dept.netSalesValue * 100 ).toFixed(2) }}%
                                </div> -->
                                <div class="column is-2 has-text-right">
                                    {{ (dept.netSalesValue / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ dept.netSalesValue | formatCurrency}}
                                </div>
                            </div>


                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Merchandise Revenue
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (totalMerchandiseRevenue / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalMerchandiseRevenue | formatCurrency}}
                                </div>
                            </div> 
                            </section>
                        </div>

                        <br>


                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Other Income
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="income.id" v-for="income in additionalIncomeByVendor">
                                <div class="column is-6 has-text-left">
                                    {{income.vendorName}}
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (income.amount / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ income.amount| formatCurrency}}
                                </div>
                            </div>

                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Other Income
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (totalOtherIncome / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalOtherIncome | formatCurrency}}
                                </div>
                            </div> 

                            </section>
                        </div>
                        <br>
                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Revenue
                                </div>
                                <div class="column is-2 has-text-right">
                                    100%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalGrossRevenue | formatCurrency}}
                                </div>
                            </div> 
                            </section>                                                                                              
                        </div>

                    </div>

                    <div class="panel">

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Fuel Cost of Goods Sold
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="fuel.product" v-for="fuel in profitAndLossData.fuelSalesList">
                                <div class="column is-4 has-text-left">
                                    <!-- {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }} -->
                                    {{ toTitleCase(fuel.product) }}
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ fuel.costPerGal | formatCurrency}}
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (((fuel.saleAmount - fuel.costAmount) / fuel.costAmount) * 100).toFixed(2) }}%
                                </div>                                
                                <div class="column is-4 has-text-right">
                                    {{ fuel.costAmount | formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Fuel COGS
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ (((totalFuelRevenue - totalFuelCost) / totalFuelCost) * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalFuelCost | formatCurrency}}
                                </div>
                            </div> 
                            </section>                                                                                              
                        </div>
                    </div>

                    <div class="panel">

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Merchandise Cost of Goods Sold ( {{ getSetting('profitCalculationMethod')}})
                                </div>
                            </div>
                            <div class="columns">
                                
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="dept.storeMapping" v-for="dept in sortArrayByProp(deptSales,'storeMappingName', 'asc')">
                                <div class="column is-4 has-text-left">
                                    {{dept.storeMappingName}}
                                </div>
                                <div class="column is-2 has-text-right">
                                </div>
                                <div class="column is-2 has-text-right">
                                    <span v-if="getSetting('profitCalculationMethod') == 'Markup'">
                                        {{ (dept.cost > 0 ? ((dept.netSalesValue - dept.cost) / dept.cost * 100 ).toFixed(2) : 0) }}%
                                    </span>
                                    <span v-if="getSetting('profitCalculationMethod') == 'Margin'">
                                        {{ ( dept.netSalesValue > 0 ? ((dept.netSalesValue - dept.cost) / dept.netSalesValue * 100 ).toFixed(2) : 0) }}%
                                    </span>
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ dept.cost | formatCurrency}} 
                                </div>
                            </div>
                            <div class="columns">
        <!-- <tool-tip>
            <div slot="title" class="help-tooltip-header">
            Markup %<br>(Eg: 20% of Cost)
            </div>
            <div slot="content" class="help-tooltip-content">
                revenue = cost x ((1 + percentage)/100)<br>
                $120 = $100 * 1.2 
         
            </div>                                              
            <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
        </tool-tip> -->
                            </div>
                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Merchandise COGS 
                                </div>
                                <div class="column is-2 has-text-right">
                                    <span v-if="getSetting('profitCalculationMethod') == 'Markup'">
                                        {{ (totalMerchandiseCost != 0 ? (totalMerchandiseRevenue - totalMerchandiseCost) / totalMerchandiseCost * 100 : 0).toFixed(2) }}%
                                    </span>
                                    <span v-if="getSetting('profitCalculationMethod') == 'Margin'">
                                        {{ ( totalMerchandiseRevenue != 0 ? (( totalMerchandiseRevenue - totalMerchandiseCost) / totalMerchandiseRevenue * 100) : 0).toFixed(2) }}%
                                    </span>
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalMerchandiseCost | formatCurrency}}
                                </div>
                            </div>
                            </section>
                        </div>
                        <br>
                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Cost of Goods Sold
                                </div>
                                <div class="column is-2 has-text-right">
                                    <!-- {{ (100 - (totalCostOfGoodsSold / totalGrossRevenue * 100)).toFixed(2) }}% -->
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalCostOfGoodsSold | formatCurrency}}
                                </div>
                            </div> 

                            </section>                                                                                              
                        </div>
                            <br>
                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Gross Profit
                                </div>
                                <div class="column is-2 has-text-right">
                                    <!-- {{ ( (totalGrossRevenue - totalCostOfGoodsSold) / (totalMerchandiseRevenue + totalFuelRevenue) * 100).toFixed(2) }}% -->
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalGrossRevenue - totalCostOfGoodsSold | formatCurrency}}
                                </div>
                            </div>   
                            </section>                                                                                              
                        </div>
                    </div>

                    <div class="panel">

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Expenses
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="expense.expenseTypeName" v-for="expense in profitAndLossData.expensesList">
                                <div class="column is-6 has-text-left">
                                    {{toTitleCase(expense.expenseTypeName) }} 
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ( expense.amount / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ expense.amount | formatCurrency}}
                                </div>
                            </div>


                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Total Operating Expenses
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ( totalOperatingExpense / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalOperatingExpense | formatCurrency}}
                                </div>
                            </div>
                            <br>    
                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Net Profit
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ((totalGrossRevenue - totalCostOfGoodsSold - totalOperatingExpense) / totalGrossRevenue * 100).toFixed(2)  }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ (totalGrossRevenue - totalCostOfGoodsSold - totalOperatingExpense) | formatCurrency }}
                                </div>
                            </div>                        
                            </section>
                        
                        </div>

                    </div>

                    <div class="panel">

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Sales Tax
                                </div>
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-6 has-text-left">
                                    Sales Tax Collected (add)
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ( salesTax.totalTaxes / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ salesTax.totalTaxes | formatCurrency}}
                                </div>

                            </div>

                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-6 has-text-left">
                                    Total Sales Tax Paid (subtract)
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ( salesTax.salesTaxPaid / totalGrossRevenue * 100).toFixed(2) }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ salesTax.salesTaxPaid | formatCurrency}}
                                </div>

                            </div> 
                            <br>

                            <div class="columns is-mobile total-row">
                                <div class="column is-6 has-text-left">
                                    Net Profit after Sales Tax
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ ((totalGrossRevenue - totalCostOfGoodsSold - totalOperatingExpense + salesTax.totalTaxes - salesTax.salesTaxPaid) / totalGrossRevenue * 100).toFixed(2)  }}%
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ (totalGrossRevenue - totalCostOfGoodsSold - totalOperatingExpense + salesTax.totalTaxes - salesTax.salesTaxPaid ) | formatCurrency }}
                                </div>
                            </div>                             
                            </section>

                        </div>
                    </div>

                </div>

                <div class="column">

                    <div class="panel">

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Lottery
                                </div>
                            </div> 
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Instant Sales 
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ lotterySales.instantLotterySales | formatCurrency}}
                                </div>                            
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Instant Payouts 
                                </div>
                                <div class="column is-4 has-text-right" :class="{'has-text-danger': lotterySales.instantLotteryPaidOut > 0.00 }">
                                    {{ lotterySales.instantLotteryPaidOut == 0.00 ? lotterySales.instantLotteryPaidOut : lotterySales.instantLotteryPaidOut * -1| formatCurrency}}
                                </div>                            
                            </div>                                                             
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Online Sales  
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ lotterySales.onlineLotterySales | formatCurrency}}
                                </div>                            
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-if="profitAndLossData.totalOnlineLottoCancel > 0.00">
                                <div class="column is-8 has-text-left">
                                    Included in POS Cash / Online Cancel
                                </div>
                                <div class="column is-4 has-text-right" :class="{'has-text-danger': profitAndLossData.totalOnlineLottoCancel > 0.00 }">
                                    {{ profitAndLossData.totalOnlineLottoCancel * -1| formatCurrency}}
                                </div>                            
                            </div>                            
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Online Payouts 
                                </div>
                                <div class="column is-4 has-text-right" :class="{'has-text-danger': lotterySales.onlineLotteryPaidOut > 0.00 }">
                                  {{ lotterySales.onlineLotteryPaidOut == 0.00 ? lotterySales.onlineLotteryPaidOut : lotterySales.onlineLotteryPaidOut * -1| formatCurrency}}
                                </div>                            
                            </div>

                            <div class="columns is-mobile bottom-bordered-row" v-if="profitAndLossData.totalLotteryMachineWithdraw > 0.00">
                                <div class="column is-8 has-text-left">
                                    Machine Instant Lottery Sales   
                                </div>
                                <div class="column is-4 has-text-right">
                                  {{ profitAndLossData.totalLotteryMachineWithdraw > 0.00 ? profitAndLossData.totalLotteryMachineWithdraw : 0| formatCurrency}}
                                </div>                            
                            </div>   
                            <div class="columns is-mobile total-row">
                                <div class="column is-8 has-text-left">
                                    Net Lottery
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ (lotterySales.onlineLotterySales 
                                       - lotterySales.onlineLotteryCanceled 
                                       - lotterySales.onlineLotteryPaidOut 
                                       + lotterySales.instantLotterySales 
                                       - lotterySales.instantLotteryPaidOut 
                                       + profitAndLossData.totalLotteryMachineWithdraw) | formatCurrency }}
                                </div>
                            </div> 

                            <div class="columns is-mobile bottom-bordered-row" v-if="profitAndLossData.totalLotteryMachineSold > 0.00">
                                <div class="column is-8 has-text-left">
                                    Machine Lottery Cash Taken
                                </div>
                                <div class="column is-4 has-text-right">
                                  {{ profitAndLossData.totalLotteryMachineSold > 0.00 ? profitAndLossData.totalLotteryMachineSold  : 0| formatCurrency}}
                                </div>                            
                            </div>
                               
                            </section>
                        </div>
                    </div> 

                    <br>
                    <!-- <div class="panel">
                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Taxable Merchandise Sales
                                </div>
                            </div> 
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="tax.name" v-for="tax in taxedMerchandise">
                                <div class="column is-8 has-text-left">
                                    <span class="is-capitalized">{{ tax.name.toLowerCase() }}</span>
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ (tax.taxableSales + tax.taxableRefunds) | formatCurrency }}
                                </div>
                            </div>
                            
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column is-8 has-text-left">
                                    Total Merchandise Sales
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ totalMerchandiseRevenue | formatCurrency}}
                                </div>
                            </div>
                            </section>
                        </div>
                    </div>

                    <br> -->

                    <div class="panel">

                        <div class="panel-block">
                            <section class="control">
                            <div class="columns is-mobile bottom-bordered-row total-row">
                                <div class="column has-text-centered">
                                    Merchandise Sales & Tax
                                </div>
                            </div> 
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="tax.name" v-for="tax in taxedMerchandise">
                                <div class="column is-4 has-text-left">
                                    <span>{{ toTitleCase(tax.name) }}</span>
                                </div>
                                <div class="column is-3 has-text-right">
                                    {{ (tax.taxableSales + tax.taxableRefunds) | formatCurrency }}
                                </div>
                                <div class="column is-2 has-text-right">
                                    {{ tax.taxRate }}%
                                </div>                                 
                                <div class="column is-3 has-text-right">
                                    {{ tax.netTax | formatCurrency}}
                                </div>
                            </div>                                
                            <div class="columns is-mobile total-row">
                                <div class="column is-4 has-text-left">
                                    Total Sales & Tax
                                </div>
                                <div class="column is-3 has-text-right">
                                    {{ totalMerchandiseRevenue | formatCurrency}}
                                </div>
                                <div class="column is-5 has-text-right">
                                    {{ salesTax.totalTaxes | formatCurrency}}
                                </div>                            
                            </div>
                            <div class="columns is-mobile bottom-bordered-row">
                                <div class="column is-8 has-text-left">
                                    Sales Tax Paid  
                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ salesTax.salesTaxPaid | formatCurrency}}
                                </div>                            
                            </div>
                            <div class="columns is-mobile bottom-bordered-row" v-bind:key="dept.department" v-for="dept in otherTaxes">
                                <div class="column is-8 has-text-left">

                                    <!-- {{ dept.department[0].toUpperCase() + dept.department.substr(1).toLowerCase() }} -->
                                    {{ toTitleCase(dept.department) }}

                                </div>
                                <div class="column is-4 has-text-right">
                                    {{ dept.netSalesValue | formatCurrency}}
                                </div>
                            </div>

                            </section>
                        </div>
                    </div>
                    <br>

                    <div class="panel">

                            <div class="panel-block">
                                <section class="control">                                                             
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column has-text-centered">
                                        Fuel Sales
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row" v-bind:key="fuel.product" v-for="fuel in profitAndLossData.fuelSalesList">
                                    <div class="column is-3 has-text-left">
                                        <!-- {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }} -->
                                        {{ toTitleCase(fuel.product) }}
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ fuel.saleVolume.toFixed(0) }} gal
                                    </div>
      
                                    <div class="column is-5 has-text-right">
                                        {{ fuel.saleAmount | formatCurrency}}
                                    </div>                               
                                </div>
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column is-3 has-text-left">
                                        Total Fuel Sales
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ Math.round(totalFuelVolume)  }} gal
                                    </div>                                

                                    <div class="column is-5 has-text-right ">
                                        {{ totalFuelRevenue | formatCurrency}}
                                    </div>
                                </div>
                                </section>
                            </div>                         

                    </div>
                    <br>
                    <div class="panel">

                            <div class="panel-block">
                                <section class="control">                                                         
                                    <div class="columns is-mobile bottom-bordered-row total-row">
                                        <div class="column has-text-centered">
                                            Fuel Invoice
                                        </div>
                                    </div>
                                    <div class="columns is-mobile bottom-bordered-row">
                                        <div class="column is-3 has-text-left">
                                            Total Fuel Invoiced
                                        </div>
                                        <div class="column is-4 has-text-right">
                                            {{ totalFuelVolumePurchased }} gal
                                        </div>
                                        <!-- <div class="column is-1 has-text-right">
                                            {{ (totalFuelPurchase / totalFuelVolumePurchased) | formatCurrency }}
                                        </div> -->

                                        <div class="column is-5 has-text-right">
                                            {{ totalFuelPurchase | formatCurrency }}
                                        </div>
                                    </div>
                                </section>
                            </div>                        

                        </div>                    
                        <br>
                        <div class="panel">

                            <div class="panel-block">
                            <section class="control">
                                    <div class="columns is-mobile bottom-bordered-row total-row">
                                        <div class="column has-text-centered">
                                            Fuel Delivery Volume
                                        </div>
                                    </div>
                                    <div class="columns is-mobile bottom-bordered-row" v-bind:key="fuel.product" v-for="fuel in fuelDeliveredByType">
                                        <div class="column is-6 has-text-left">
                                            <!-- {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }} -->
                                            {{ toTitleCase(fuel.product) }}
                                        </div>
                                        <div class="column is-6 has-text-right">
                                            {{ fuel.deliverdVol.toFixed(0) }} gal
                                        </div>
                                    </div>
                                    <div class="columns is-mobile bottom-bordered-row total-row">
                                        <div class="column is-6 has-text-left">
                                            Total Fuel Volume Delivered
                                        </div>
                                        <div class="column is-6 has-text-right">
                                            {{ totalFuelVolumeDelivered.toFixed(0) }} gal
                                        </div>
                                    </div>                                                                
                                </section>                            
                            </div>
                        </div>
                        <br>
                        <div class="panel">
                            <div class="panel-block">
                            <section class="control">
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column has-text-centered">
                                        Fuel Not Invoiced
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row ">
                                    <div class="column is-6 has-text-left">
                                        Fuel Volume (Delivery - Invoiced)
                                    </div>
                                    <div class="column is-6 has-text-right">
                                        {{ (totalFuelVolumeDelivered - totalFuelVolumePurchased).toFixed(0) }} gal
                                    </div>
                                </div>
                            </section>
                            </div>
                        </div>
                        <br>                    
                        <div class="panel">
                            <div class="panel-block">
                            <section class="control">                   
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column has-text-centered">
                                        Fuel Per Gal Cost/Price/Profit
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Avg. Cost/gal. 
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ fuelAverageCost | formatCurrency }}
                                    </div>                            
                                </div>

                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Avg. Price/gal.  
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ ( totalFuelRevenue / totalFuelVolume) | formatCurrency }}
                                    </div>                            
                                </div>

                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column is-8 has-text-left">
                                        Profit/gal. 
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ ( totalFuelRevenue / totalFuelVolume) - fuelAverageCost | formatCurrency}}
                                    </div>                            
                                </div>
                                </section>
                            </div>
                        </div>

                        <br>

                        <div class="panel">

                                <div class="panel-block">
                                    <section class="control">
                                    <div class="columns is-mobile bottom-bordered-row total-row">
                                        <div class="column has-text-centered">
                                            Inventory Purchased
                                        </div>
                                    </div>
                                    <div class="columns is-mobile bottom-bordered-row" v-bind:key="inventory.deptMapping" v-for="inventory in inventoryPurchase">
                                        <div class="column is-8 has-text-left">
                                            <!-- {{ inventory.deptMapping[0].toUpperCase() + inventory.deptMapping.substr(1).toLowerCase() }} -->
                                            {{ toTitleCase(inventory.deptMapping) }}
                                        </div>
                                        <div class="column is-4 has-text-right">
                                            {{ inventory.expenseCost | formatCurrency}}
                                        </div>

                                    </div>
                                    <div class="columns is-mobile total-row">
                                        <div class="column is-8 has-text-left">
                                            Total Inventory Purchase
                                        </div>
                                        <div class="column is-4 has-text-right">
                                            {{ totalInventoryPurchase | formatCurrency }}
                                        </div>
                                    </div>   
                                    </section>
                                </div>
                        </div>

                        <div class="panel">

                            <div class="panel-block" v-if="profitAndLossData.deposits">
                                <section class="control">
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column has-text-centered">
                                        Deposits/Disbursements
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Deposits - General Account
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.deposits.bankDepositGeneral | formatCurrency}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Deposits - Other Account
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.deposits.bankDepositLottery | formatCurrency}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Other Cash Disbursements
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.deposits.bankDepositAtm | formatCurrency}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Cash Loaded in ATM
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.totalCashLoadedInAtm | formatCurrency}}
                                    </div>
                                </div>
                                <div class="columns is-mobile total-row">
                                    <div class="column is-8 has-text-left">
                                        Total Deposits/Disbursements
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ totalCashDisbursements  | formatCurrency }}
                                    </div>
                                </div>   
                                </section>
                            </div>
                        </div>

                        <div class="panel">

                            <div class="panel-block" v-if="profitAndLossData.deposits">
                                <section class="control">
                                <div class="columns is-mobile bottom-bordered-row total-row">
                                    <div class="column has-text-centered">
                                    Cigarette
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Open Count
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.previousCigaretteCloseCount}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Buy Count
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.cigBuyCount}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row">
                                    <div class="column is-8 has-text-left">
                                        Sale Count
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.cigDeptSaleItemCount * -1}}
                                    </div>
                                </div>
                                <div class="columns is-mobile total-row">
                                    <div class="column is-8 has-text-left">
                                        Balance
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.previousCigaretteCloseCount 
                                            + profitAndLossData.cigBuyCount
                                            - profitAndLossData.cigDeptSaleItemCount }}
                                    </div>
                                </div>  
                                <div class="columns is-mobile">
                                    <div class="column is-8 has-text-left">
                                        Close Count
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.cigCloseCount }}
                                    </div>
                                </div>                                                  
                                <div class="columns is-mobile total-row">
                                    <div class="column is-8 has-text-left">
                                        Variance
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ (profitAndLossData.previousCigaretteCloseCount 
                                            + profitAndLossData.cigBuyCount
                                            - profitAndLossData.cigDeptSaleItemCount 
                                            - profitAndLossData.cigCloseCount) * -1 }}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row" v-if="profitAndLossData.cigDeptSales > 0">
                                    <div class="column is-8 has-text-left">
                                        Total Sales Amount
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.cigDeptSales | formatCurrency}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row" v-if="profitAndLossData.cigDeptSales > 0">
                                    <div class="column is-8 has-text-left">
                                        Average Sales Price
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.cigDeptSales / profitAndLossData.cigDeptSaleItemCount  | formatCurrency}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row" v-if="profitAndLossData.cigBuyAmount > 0">
                                    <div class="column is-8 has-text-left">
                                        Total Buy Amount
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.cigBuyAmount | formatCurrency}}
                                    </div>
                                </div>
                                <div class="columns is-mobile bottom-bordered-row" v-if="profitAndLossData.cigBuyAmount > 0">
                                    <div class="column is-8 has-text-left">
                                        Average Purchase Cost
                                    </div>
                                    <div class="column is-4 has-text-right">
                                        {{ profitAndLossData.cigBuyAmount / profitAndLossData.cigBuyCount  | formatCurrency}}
                                    </div>
                                </div>  
                                </section>
                            </div>
                        </div>

                        <div class="panel">

                                <div class="panel-block" v-if="profitAndLossData.totalCarWashSold > 0 || profitAndLossData.totalPhoneCardSold > 0">
                                    <section class="control">
                                    <div class="columns is-mobile bottom-bordered-row total-row">
                                        <div class="column has-text-centered">
                                        Other
                                        </div>
                                    </div>
                                    <div class="columns is-mobile bottom-bordered-row">
                                        <div class="column is-8 has-text-left">
                                            Car Wash Sold
                                        </div>
                                        <div class="column is-4 has-text-right">
                                            {{ profitAndLossData.totalCarWashSold}}
                                        </div>
                                    </div>
                                     <div class="columns is-mobile bottom-bordered-row">
                                        <div class="column is-8 has-text-left">
                                            Phone Cards Sold
                                        </div>
                                        <div class="column is-4 has-text-right">
                                            {{ profitAndLossData.totalPhoneCardSold}}
                                        </div>
                                    </div>
                                    </section>
                                </div>
                        </div>
                    </div>                    
                    <revenue-summary-pdf
                        ref="pdfReport"
                        :isShowPDFDialog="isShowPDFDialog"
                        :dateRange="salesDateRange"
                        :profitAndLossData="profitAndLossData"
                        :summaryNumbers="summaryNumbers"
                        :fuelDeliveredByType="fuelDeliveredByType"
                        @close-pdf-dialog="togglePDFDialog"
                    >
                    </revenue-summary-pdf>                    
                </div>
            
            </div>
        </div>
    <!-- </div> -->
    
</template>

<script>

import UserStore from "../../../store/UserStore"
import moment from 'moment'
import axios from 'axios'
import DateRangeSelector from '../../../components/app/DateRangeSelector'
// import ToolTip from '../../../components/app/ToolTip'
import jspdf from "jspdf"
import RevenueSummaryPdf from './RevenueSummaryPDF.vue'

export default {

    components: {
        // ToolTip,
        DateRangeSelector,
        RevenueSummaryPdf
    },

    metaInfo: {
        title: 'Revenue Summary'
    },

    data() {
        return {
            showStoreName: false,
            isLoading: true,
            isFullPage: true,
            salesDateRange: [],
            user: [],
            endDate: new Date().toISOString().split('T')[0],
            minDate: new Date(2016, 8, 1),
            maxDate: new Date(),
            startDate: new Date(),
            month: new Date(),
            deptMappingsRevenue: [],
            taxedMerchandise: [],
            profitAndLossData: {totalFuelVolumePurchased: 0.00},
            totalFuelRevenue: 0.00,
            totalFuelCost: 0.00,
            totalFuelVolume: 0.00,
            totalMerchandiseRevenue: 0.00,
            totalOtherIncome: 0.00,
            totalGrossRevenue: 0.00,
            totalFuelProfit: 0.00,
            totalMerchandiseCost: 0.00,
            totalMerchandiseProfit: 0.00,
            totalCostOfGoodsSold: 0.00,
            totalProfit: 0.00,
            totalOperatingExpense: 0.00,
            fuelProfitPerGallon: 0.00,
            fuelAverageCost: 0.00,
            totalInventoryPurchase: 0.00,
            fuelPurchase: 0.00,
            totalFuelPurchase: 0.00,
            inventoryPurchase: [],
            totalFuelVolumePurchased: 0.00,
            lotterySales: {},
            salesTax: {},
            deptSales: [],
            storeMappings: UserStore.getters.getStoreMappings,
            otherTaxes: [],
            fuelDelivered: [],
            fuelDeliveredByType: [],
            additionalIncomeByVendor: [],
            isShowPDFDialog: false,
            summaryNumbers: {},
            totalSales: {},
        }
    },

    methods: {

        fetchData() {

            if (UserStore.getters.getActiveStore) {

                this.isLoading = true

                // const loadingComponent = this.$buefy.loading.open({
                //     container: this.isFullPage ? null : this.$refs.element.$el
                // })                

                this.resetMonthData()
                // this.startDate = (new Date(this.month.getFullYear(), this.month.getMonth(), 1)).toISOString().split('T')[0]                    
                // this.endDate = (new Date(this.month.getFullYear(), this.month.getMonth() + 1, 0)).toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/profit?size=50"  + "&fromDate="+this.salesDateRange[0]+"&toDate="+this.salesDateRange[1]

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.profitAndLossData = response.data.data
                        this.getDeptMappingsRevenue()
                        this.fetchFuelDelivery()
                        // this.fetchStoreSales()
                        this.groupAdditionalIncomeByVendor()
                        // loadingComponent.close()
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404 || err.response.status === 400) {
                            this.$buefy.toast.open({
                                message: 'No shift data found',
                                type: 'is-warning'
                            })
                        }
                        // loadingComponent.close()
                    })
            }
        },

        fetchFuelDelivery() {
            
            if (UserStore.getters.getActiveStore) {
                
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + UserStore.getters.getActiveStore.storeId
                            + '/tanks/deliveries'  + "?start_date="+this.salesDateRange[0]+"&end_date="+this.salesDateRange[1]
                            // + '/tanks/deliveries?page=1&size=50'
                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.fuelDelivered = response.data.data
                        this.groupedDeliveryByFuel()

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        },

        groupedDeliveryByFuel() {

            var result = []
            this.fuelDelivered.reduce(function(res, value) {
                if (!res[value.product]) {
                    res[value.product] = { product: value.product, deliverdVol: 0 }
                    result.push(res[value.product])
                }
                res[value.product].deliverdVol += (value.endingFuelVolume - value.startingFuelVolume)
                return res
            }, {})

            this.fuelDeliveredByType = result

        },
        
        groupAdditionalIncomeByVendor() {

            var sortedIncomes = this.profitAndLossData.additionalIncomeList.sort((a, b) => {
                let fa = a.vendorName.toLowerCase(),
                    fb = b.vendorName.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
            var result = []
            sortedIncomes.reduce(function(res, value) {
                if (!res[value.vendorName]) {
                    res[value.vendorName] = { vendorName: value.vendorName, amount: 0 }
                    result.push(res[value.vendorName])
                }
                res[value.vendorName].amount += value.amount
                return res
            }, {})

            this.additionalIncomeByVendor = result
            this.profitAndLossData.additionalIncomeByVendor = this.additionalIncomeByVendor
        }, 

        getDeptMappingsRevenue() {
            
            this.deptMappingsRevenue = this.profitAndLossData.deptRevenueAndCostList.filter((dept) => {
                // return dept.storeMapping !== 'Taxes'
                return dept
            })

            this.otherTaxes = this.profitAndLossData.deptRevenueAndCostList.filter((dept) => {
                return dept.storeMapping == 'Taxes'
            })
            
            this.fuelPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
                return inventory.deptMapping === 'Fuel'
            })

            this.inventoryPurchase = this.profitAndLossData.inventoryExpenseList.filter((inventory) => {
                return inventory.deptMapping !== 'Fuel'
            })

            this.inventoryPurchase.forEach((inv) => {
                if (inv.deptMapping == 'BeerWine') {
                    inv.deptMapping = 'Beer & Wine'
                }
                if (inv.deptMapping == 'Spirit') {
                    inv.deptMapping = 'Spirit / Liquor'
                }
                if (inv.deptMapping == 'InsideSales') {
                    inv.deptMapping = 'Inside Sales'
                }
            })

            this.taxedMerchandise = this.profitAndLossData.taxInfoList.filter((tax) => {
                if (tax.netTax > 0) {
                    return tax
                }
            })

            this.totalInventoryPurchase = 0.00
            this.totalMerchandiseCost = 0.00

            this.inventoryPurchase.forEach(item => {
                this.totalInventoryPurchase += item.expenseCost
            })
            
            this.totalFuelPurchase = 0.00
            this.fuelPurchase.forEach(item => {
                this.totalFuelPurchase += item.expenseCost
            })

            this.deptMappingsRevenue.forEach(mapping => {
                // if (mapping.storeMapping === "Taxes") {
                //     return
                // }
               
                if (mapping.storeMapping == null | mapping.storeMapping == "") {
                    mapping.storeMapping = "Other"
                }

                var mapName = this.storeMappings.find((storeMap) => storeMap.value === mapping.storeMapping)
                if (!mapName) mapName = "Other" 
                                
                mapping.calcCost = this.calculateMercandiseCost(mapping.netSalesValue, mapping.percentage) 

                var map = this.deptSales.find((map) => map.storeMapping === mapping.storeMapping)
                if (map) {
                        map.netSalesValue += this.getNumber(mapping.netSalesValue),
                        // map.profit += mapping.profit
                        map.profit += this.getNumber(mapping.netSalesValue) - this.getNumber(mapping.cost)
                        map.cost += this.getNumber(mapping.calcCost),
                        // map.cost = 5000,
                        map.percentage = (map.netSalesValue - map.cost) / map.cost * 100
                } else {
                    this.deptSales.push({
                        "storeMappingName": mapName.name,
                        "storeMapping": mapping.storeMapping,
                        "netSalesValue": this.getNumber(mapping.netSalesValue),
                        // "cost": mapping.cost,
                        // "profit": mapping.profit,  
                        "percentage": this.getNumber(mapping.percentage),
                        "cost": this.getNumber(mapping.calcCost),
                        "profit": this.getNumber(mapping.netSalesValue) - this.getNumber(mapping.cost)                

                    })
                }

                this.totalMerchandiseRevenue += this.getNumber(mapping.netSalesValue)
                this.totalMerchandiseCost += this.getNumber(mapping.calcCost)
                this.totalMerchandiseProfit += this.getNumber(mapping.profit)
            })

            this.profitAndLossData.expensesList.forEach(expense => {
                this.totalOperatingExpense += this.getNumber(expense.amount)
            })

            this.profitAndLossData.fuelSalesList.forEach(fuel => {
                this.totalFuelRevenue += this.getNumber(fuel.saleAmount)
                this.totalFuelVolume += this.getNumber(fuel.saleVolume)
            })

            this.profitAndLossData.additionalIncomeList.forEach(income => {
                this.totalOtherIncome += this.getNumber(income.amount)
            })

            this.totalFuelVolumePurchased = this.profitAndLossData.totalFuelVolumePurchased

            if (this.totalFuelVolumePurchased != 0.00) {
                this.fuelAverageCost = Math.round(this.totalFuelPurchase / this.totalFuelVolumePurchased * 100) / 100
            } else {
                this.fuelAverageCost = 0.00
            }
            
            this.totalGrossRevenue = this.totalFuelRevenue + this.totalMerchandiseRevenue + this.totalOtherIncome

            this.lotterySales = this.profitAndLossData.lottery
            if (this.lotterySales.onlineLotteryCanceled == null) {
                this.lotterySales.onlineLotteryCanceled = 0.00
            }
            this.salesTax = this.profitAndLossData.salesTax

            this.profitAndLossData.merchSalesList = this.deptSales
            this.profitAndLossData.additionalIncomeByVendor = this.additionalIncomeByVendor
            this.summaryNumbers.salesTax = this.salesTax
            this.summaryNumbers.totalFuelVolumePurchased = this.totalFuelVolumePurchased
            this.summaryNumbers.totalFuelVolumeDelivered = this.totalFuelVolumeDelivered
            this.summaryNumbers.fuelAverageCost = this.fuelAverageCost
            this.summaryNumbers.fuelProfitPerGallon = (this.totalFuelRevenue / this.totalFuelVolume) - this.fuelAverageCost
            this.summaryNumbers.totalOperatingExpense = this.totalOperatingExpense
            this.summaryNumbers.salesTax = this.salesTax

            this.profitAndLossData.fuelSalesList.forEach(fuel => {
                fuel.costPerGal = (this.getNumber(fuel.saleAmount) / this.getNumber(fuel.saleVolume)).toFixed(8) - this.summaryNumbers.fuelProfitPerGallon
                fuel.costAmount = (this.getNumber(fuel.saleVolume) * this.getNumber(fuel.costPerGal))
                this.totalFuelCost += this.getNumber(fuel.saleVolume) * this.getNumber(fuel.costPerGal)
            })            
            this.totalCostOfGoodsSold = this.totalMerchandiseCost + this.totalFuelCost
        },

        resetMonthData() {
            this.deptMappingsRevenue = []
            this.otherTaxes = []
            this.deptSales = []
            this.profitAndLossData = []
            this.lotterySales = {}
            this.salesTax = {}
            this.totalFuelRevenue = 0.00
            this.totalMerchandiseRevenue = 0.00
            this.totalGrossRevenue = 0.00
            this.totalFuelCost = 0.00
            this.totalFuelProfit = 0.00
            this.totalFuelVolume = 0.00
            this.totalMerchandiseCost = 0.00
            this.totalMerchandiseProfit = 0.00
            this.totalCostOfGoodsSold = 0.00
            this.totalProfit = 0.00
            this.totalOperatingExpense = 0.00
            this.totalOtherIncome = 0.00
            this.fuelProfitPerGallon = 0.00
            this.fuelAverageCost = 0.00
            this.totalInventoryPurchase = 0.00
            this.fuelPurchase = 0.00
            this.totalFuelPurchase = 0.00
            this.inventoryPurchase = 0.00
            this.totalFuelVolumePurchased = 0.00
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        generatePDF() {

            this.showStoreName = true

            const html = document.querySelector("#ReportContent")
            let srcwidth = html.scrollWidth

            const doc = new jspdf('p','pt','a4')

            doc.html(html, {
                html2canvas: {
                    scale: 595.26 /srcwidth,
                    scrollY: 0
                },
                filename: 'pandlreport',
                x: 0,
                y: 10,
                callback: function(pdf) {
                    window.open(pdf.output("bloburl"))
                }
            })

            // this.showStoreName = false

        },
 
        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0].toISOString().split('T')[0], dateRange[1].toISOString().split('T')[0]]
        },
                      
    },

    watch: {
        salesDateRange: function() {
            this.fetchData()
        }
    },    

    computed: {
        monthName() {
            return moment(this.month).format('MMMM')
        },

        getActiveStore() {

            return UserStore.getters.getActiveStore
        },

        totalFuelVolumeDelivered() {

            return this.fuelDeliveredByType.reduce((a, b) => {
                return a + b["deliverdVol"];
            }, 0);

        },

        totalNonTaxableSales() {
            var totalTaxableSales = this.profitAndLossData.taxInfoList.reduce((a, b) => {
                return a + b["taxableSales"] + b["taxableRefunds"];
            }, 0);
            return this.totalMerchandiseRevenue - totalTaxableSales
        },

        totalCashDisbursements() {
            return  this.profitAndLossData.deposits.bankDepositGeneral + 
                    this.profitAndLossData.deposits.bankDepositLottery + 
                    this.profitAndLossData.deposits.bankDepositAtm +
                    this.profitAndLossData.totalCashLoadedInAtm
        },

    },

    mounted() {
        this.startDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), 1).toISOString().split('T')[0]
        this.salesDateRange=[this.startDate, this.endDate]
        // this.fetchStoreSales()
    }    
}
</script>

<style>

.bottom-bordered-row {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}

.bottom-bordered-row2 {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;

}

.total-row {
    background-color:  rgb(230, 230, 230);
    font-weight: 1000;
}
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  /* background-color: none; */
  border: 2px;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* #ReportContent {
    width: 8.5in;
    height: 11.0in;
} */
</style>